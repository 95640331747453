import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";

const Thankyou = () => {
  return (
    <Layout>
      <section className='section'>
        <div className='container'>
          <div className='content'>
            <h1 className='orbitron heading'>CONTACT</h1>
            <p>
              お問い合わせありがとうございました。
              <br />
              担当者よりご連絡いたしますので、今しばらくお待ちください。
            </p>
            <div className='column is-12 has-text-centered'>
              <Link className='button is-medium' to='/'>
                トップページへ戻る
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Thankyou;
